import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"
import hybridglobalsystem from "../../assets/images/services/case-study/hybrid-global-system.png"
import CaseDetail from "../../components/Shared/casedetails"
import integrationexperts from "../../assets/images/services/case-study/integration-experts.png"
import continueupgrade from "../../assets/images/services/case-study/continue-upgrade.png"
import dayinlife from "../../assets/images/services/case-study/day-in-life.png"


const seoTitle = "Hybrid And Global Systems"
const pageTitle = "Hybrid And Global Systems"
const subTitle = "Cloud Infrastructure "
const para =
  "From infrastructure to test automation to observability dashboards to security to compliance, the modern tech stack embraces GitOps in a move toward declarative alignment of enterprise best practices."
const blockquote = `"Atyeti’s vision is to help our clients build a modern Zero-Trust
backbone that will protect the Enterprise while accelerating the
return of value to our Customers"`

const cardItems = [
  {
    id: 1,
    heading: ``,
    listItems: (
      <>
        <h6>Plan & Track</h6>
        <p>
          Ensuring that the team is working on the right thing at the right time
          by embracing iterations and change.
        </p>
        <li>
          <Icon.Square />
          Agile: milestones, themes, stories, technical debt
        </li>
        <li>
          <Icon.Square />
          Roadmaps: product, project
        </li>
        <li>
          <Icon.Square />
          Collaboration: knowledge share, communication
        </li>
        <li>
          <Icon.Square />
          Business Processes: domain driven, value stream, test management,
          release management
        </li>
        <h6>Develop</h6>
        <p>
          Providing development teams the tools needed while creating shared
          ownership of the system as whole
        </p>
        <li>
          <Icon.Square />
          Local Setup: docker, ide & code editors, source control, plugins
        </li>
        <li>
          <Icon.Square />
          Continuous Integration: build tools, artifact management
        </li>
        <li>
          <Icon.Square />
          Architecture: fault- tolerant, event-driven, microservices, strangler
          pattern
        </li>
        <li>
          <Icon.Square />
          Data Management: audit, events, materialized view, data masking,
          distributed ledger
        </li>
        <h6>Quality</h6>
        <p>
          Quality and security is a shared responsibility across the entire
          organization and team. Shift left and fail early.
        </p>
        <li>
          <Icon.Square />
          Environments: environment management
        </li>
        <li>
          <Icon.Square />
          Data: test data & database management
        </li>
        <li>
          <Icon.Square />
          Individual: unit testing, pull request, code review, code coverage,
          quality gates
        </li>
        <li>
          <Icon.Square />
          Team: functional testing, integration testing, security scanning
        </li>
        <li>
          <Icon.Square />
          Domain: end-to-end testing, non-functional, performance testing,
          penetration testing
        </li>
        <h6>Release</h6>
        <p>
          Bundling and managing application across clouds and domain while
          aligning to internal governance and security models
        </p>
        <li>
          <Icon.Square />
          Configuration Management: system setup, application requirements
        </li>
        <li>
          <Icon.Square />
          Secrets & Certificates: credentials, certificate management
        </li>
        <li>
          <Icon.Square />
          Targets: cloud, Kubernetes, virtual machines, physical machines
        </li>
        <li>
          <Icon.Square />
          Continuous Deployment: pipelines, rollout strategies
        </li>
        <h6>Operate & Measure</h6>
        <p>
          Verify that right people, services, and machines have the right
          entitlements in the right context while working on the right
          activities
        </p>
        <li>
          <Icon.Square />
          Business Intelligence: dashboards, monitoring, logging, tracing,
          alerting
        </li>
        <li>
          <Icon.Square />
          Zero Trust: identity, security, encryption, authentication,
          authorization, federation, contextualized, adaptive , RBAC
        </li>
        <li>
          <Icon.Square />
          Service Mesh: segmentation, load balancing, circuit breakers
        </li>
      </>
    ),
  },
  {
    id: 1,
    heading: `Enterprise Cloud Fundamentals`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <strong> Identity:{" "}</strong> Authentication and authorization of people, machines and
          services that can be geolocation aware and programmatically invoked
        </li>
        <li>
          <Icon.Square />
          <strong> Security:{" "} </strong>Dynamic secrets, certificates, and controls for the global,
          distributed, hybrid cloud world
        </li>{" "}
        <li>
          <Icon.Square />
          <strong>Networking:{" "}</strong> Move towards service-based (rather than ip) enabling
          flexible deployment (blue/green, canary) and application modernization
        </li>{" "}
        <li>
          <Icon.Square />
          <strong>Observability:{" "}</strong> Holistically ensure each cloud fundamental is correctly
          implemented while providing the foundation for distributed systems
        </li>
        <>
          <h6>The Approach</h6>
          <li>
            <Icon.Square />
            <strong> Automation First:{" "}</strong>
            Automated test data generation/mock and
            environment provisioning, test early and test continuously
            throughout CI & CD to achieve faster feedback & shift-left
          </li>
          <li>
            <Icon.Square />
            <strong>Shift Left:{" "}</strong> Shared responsibility and team empowerment from product
            owner through development and testing
          </li>
          <li>
            <Icon.Square />
            <strong>Embrace Change:{" "}</strong> Continuously optimize using real-time monitoring &
            feedback
          </li>
        </>
      </>
    ),
  },

  {
    id: 3,
    heading: `Building Hybrid, Global Systems`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          The modern Enterprise faces the complexity of bridging multiple
          datacenters across both public and private clouds with the goal of
          using the right ecosystem to run the right workloads in the most
          cost-effective manner, while ensuring security and operational
          efficiency.
        </li>
        <img src={hybridglobalsystem}/>
      </>
    ),
  },
  {
    id: 4,
    heading: `Continuous Upgrades`,
    listItems: (
      <>
        <p>
          HashiCorp uses “Replicated” for distributing their binaries and
          licensing agreements. “Replicated” is a great service, but it is only
          one step in the overall process .
        </p>
        <h6>Recommendations:</h6>
        <li>
          {" "}
          <Icon.Square />
          The pipeline will ensure Product upgrades within two weeks of public
          availability.
        </li>
        <li>
          {" "}
          <Icon.Square />
          Controls will be implemented to ensure Product never falls a full
          version behind the upstream.
        </li>
        <li>
          {" "}
          <Icon.Square />
          Binaries from external Products will be scanned and validated prior to
          rollout.
        </li>
        <img src={continueupgrade}/>

      </>
    ),
  },
 
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
// export const query = graphql`
//   query Automation2Query {
//     casedetailsJson(slug: { eq: "hybrid-and-global-systems" }) {
//       images {
//         image {
//           childImageSharp {
//             gatsbyImageData
//           }
//         }
//       }
//       slug
//     }
//   }
// `
export default BlogDetails
